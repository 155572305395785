#video-gallery{
    display: flex;
    justify-content: center;
    column-gap: 1rem;
    margin: 8% auto;
}

.product-video-gallery-page h1 {
    margin-bottom: 3rem;
    margin-top: 0.25em;
    text-transform: uppercase;
    text-align: center;
}

.product-video-gallery-page .pswp-gallery a:before {
    top: 50%;
}

.video-gallery-element{
    position:relative;
}

@media (max-width: 1600px) {
    #video-gallery{
        max-width: 90%;
    }
}

@media (max-width: 960px) {
    #video-gallery{  
        margin: 0 auto;
        max-width: 80%;
    }
}

