@import "../../theme/variables.scss";
// Rotate modal
.rotate-view{
    .modal-header{
        justify-content: center;
        padding:2rem 0;
    }
    .modal-body{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .rotate-icon {
        fill: $primary;
        height: 30%;
        width: 30%;
    }

    .logo-rotate-modal {
        width: 30vw;
        height: auto;
    }
}