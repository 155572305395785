/**ProductLandingPage**/

.product-landing-page {
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    text-align: center;
    position:relative;
}

.product-landing-page:after {
  width: 100%;
  position:absolute;
  content: "";
  bottom: 0;
  left: 0;
    background: url(./images/gray-back.png) no-repeat left bottom;
    background-blend-mode: multiply;
    background-size: 100%;
    height: 100%;
   opacity: .5;
}

.product-landing-page h1 {
    margin-top: .25em;
    text-transform: uppercase;
}

.product-landing-page h2 {
    font-size: 50px;
    font-size: clamp(1.125rem, -0.2493rem + 2.812vw, 3.125rem) !important;
    font-family: "BrutalType-Light";
    // letter-spacing: 20% !important;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: .5em 0 .3em;
}

.product-landing-page h3 {
    text-align: center;
    color: $primary;
    font-size: 52px;
    font-size: clamp(1.125rem, -0.3352rem + 2.9877vw, 3.25rem) !important;
    font-family: "BrutalType-Bold" !important;
    z-index: 99;
}

.product-landing-page .product-image {
    // min-height: 50vh;
    // height: 100%;
    margin-top: -6%;
    // margin: -2% auto 0;
    width: 80%;
    z-index: 98;
}

.product-landing-page h1 {
    margin-bottom: 1rem !important;
}

@media (max-width: 992px) { 

    .product-landing-page .product-image {
   margin-top: 0;
        width: 60%;

    }

}