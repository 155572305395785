/**ProductMainPage**/

.product-main-page h1{
    text-align: center;
    margin:.25em auto .75em;
}

.product-advantages{
    max-width: 75%;
    margin: 7% auto;
}

.product-main-page .box{
    background-color: $primary;
    padding: 1rem;
    color: #fff;
    text-align: center;
    height: 336px;
    max-height: 30vh;
    font-family: "BrutalType-Bold";
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: clamp(1rem, 0.5237rem + 1.2302vw, 2rem);
}

.product-main-page .product-description{
    font-size: 52px;
    font-size: clamp(1.125rem, -0.3352rem + 2.9877vw, 3.25rem);
    padding: 0 30%;
    text-align: center;
    font-family: "BrutalType-Regular";
    line-height: 120%;

}

.product-main-page .box img{
    max-width: 150px;
    margin: 0 auto 0;
    width: 100%;
}

.product-main-page .box h2{
   font-size: 32px;
   font-size: clamp(1.125rem, 0.5237rem + 1.2302vw, 2rem);
   min-height: 3em;
}



@media (max-width: 992px) { 

  .product-main-page .box {
    max-height: 33vh;
  }

  .product-main-page .box img{
    max-width: 60px;

}
.product-advantages{
    max-width: 80%;
    margin: 3% auto 0;
}
.product-main-page h1{
    margin-top:1.5rem !important;
}

.product-main-page .box h2{
    font-size: 1rem;
    margin: 0;
}

.product-main-page .product-description {
    padding: 0 20%;
}

}