.pswp__youtube-video-container {
  position: relative;
  width: 90%;
  height: 90%;
  pointer-events: none;
}
.pswp__youtube-video-container iframe {
  background: #444;
  width: 90%;
  height: 90%;
  pointer-events: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
