/**ProductPage**/

.product-page {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
}

.product-page h1 {
    font-size: clamp(1.625rem, -3.5417rem + 10.7639vw, 9.375rem);
    margin-left: -10px;
    margin-bottom: 2rem;

}

@media (max-width: 960px) {
    .product-page h1 {
        margin-left: 0;
        margin-bottom: 0 !important;
    }
}

.product-page h2 {
    font-size: 42px;
    font-size: clamp(1.25rem, 0.3051rem + 1.9332vw, 2.625rem);
    line-height: 110%;
}

.product-page .css-4ojo4i h2 {
    letter-spacing: 0.25rem;
}

.css-4ojo4i {
    position: relative;
    z-index: 9999;
}

.product-page h3 {
    font-family: "BrutalType-light";
    font-size: 32px;
    font-size: clamp(1rem, 0.5237rem + 1.2302vw, 2rem);
}

.product-page h4 {
    font-size: 32px;
    font-size: clamp(1.125rem, 0.5237rem + 1.2302vw, 2rem);
}

.product-page p {

    font-size: 32px;
    font-size: clamp(1rem, 0.5237rem + 1.2302vw, 2rem);

}

.product-page p.product-description div p {
    margin: 2rem 0 5rem;
    line-height: 120%;
}

.product-page p.product-description strong {
    font-family: "BrutalType-Bold";
}

.icons {
    z-index: 9999;
}

.product-page .col-md-2 {
    max-width: 100px;
    width: 100px;
}



.slide-image-container {
    overflow: hidden;
    width: 80px;
    height: 80px;
    margin-top: 5px;
}

.product-page .col-md-2 img {
    width: 100px;
    height: 100px;
    margin-left: -10px;
    margin-top: -10px;
}

.product-page .product-title-container {
    height: 20vh;
}



.product-data-container ul {
    font-size: 24px;
    font-size: clamp(0.8rem, 0.4455rem + 0.8787vw, 1.5rem);
}

.product-data-container ul {
    list-style: none;
    /* Remove default bullets */
}

.product-data-container ul li::before {
    content: "\2022";
    /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #ed9b33;
    /* Change the color */
    font-weight: bold;
    /* If you want it to be bold */
    display: inline-block;
    /* Needed to add space between the bullet and the text */
    width: 1em;
    /* Also needed for space (tweak if needed) */
    margin-left: -1em;
    /* Also needed for space (tweak if needed) */
}

.product-data-container p strong {
    font-family: "BrutalType-Bold";
}

.product-data-container ul li {
    margin-bottom: 1rem;
}

.rounded-icon-bg {
    position: relative;
    background: #fff;
    border-radius: 100%;
    cursor: pointer;
    width: 60px;
    height: 60px;
    color: #ccc;
    text-align: center;
    line-height: 60px;
    top: 20px;
    right: 20px;
    margin-left: 20px;
}

.swiper-button-prev {
    left: 30px;
}

.swiper-button-next {
    right: 30px;
}



/**argazkien maketazioa**/

.product-page .col-7 .row {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    overflow: hidden;
}


.product-page .product-imagecollage .row,
.product-page .product-imagecollage .col {
    margin: 0;
    padding: 0;
}

.slide_type_1,
.slide_type_2,
.slide_type_3,
.slide_type_4 .slide_type_5,
.slide_type_6,
.slide_type_7,
.slide_type_8 {
    height: 100vh;
}

.slide_type_3 .row,
.slide_type_4 .row {
    height: 50vh;
    overflow: hidden;
}

.slide_type_7 .col {
    height: 50%;
    overflow: hidden;
}

.product-page .col-7 .row.icons {
    overflow: visible;
}

.slide_type_4 .col {
    width: 50%;
    overflow: hidden;
}

.slide_type_7 .row .col .row .col {
    height: 50vh;
    overflow: hidden;
}

.slide_type_7 .row>.col {
    height: 100vh;
    overflow: hidden;
    padding-left: 0;
}

.slide_type_7 .row>.col.border-4 {
    padding: 0;
}

/**Irudien tamaina**/


#my-gallery {
    // height: 100vh;
    height: 100%;
}

.slide_type_1 img,
.slide_type_2 img,
.slide_type_3 img,
.slide_type_4 img,
.slide_type_5 img,
.slide_type_6 img,
.slide_type_7 img,
.slide_type_8 img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    overflow: hidden;
    height: 100%;

}


/*1 - Single image*/
.slide_type_1_img_1 {
    // min-height: 1080px;
    // max-height: 1080px;
}

/*2 - 4 square images*/
.slide_type_2_img_1,
.slide_type_2_img_2,
.slide_type_2_img_3,
.slide_type_2_img_4 {
    // min-height: 540px;
    // max-height: 540px;
}

/*3 - Two Horizontal images*/
.slide_type_3_img_1,
.slide_type_3_img_2 {
    // min-height: 540px;
    // max-height: 540px;
}


/*4 - 1 horizontal 2 square images*/
.slide_type_4_img_1,
.slide_type_4_img_2,
.slide_type_4_img_3 {
    // min-height: 540px;
    // max-height: 540px;
}

/*5 - 2 square 1 horizontal image*/
.slide_type_5_img_1,
.slide_type_5_img_2,
.slide_type_5_img_3 {
    // min-height: 540px;
    // max-height: 540px;
}

/*6 -  2 vertical images*/

.slide_type_6_img_1,
.slide_type_6_img_2 {
    // min-height: 1080px;
    // max-height: 1080px;
}

/*7 - 1 vertical and 2 square images*/
.slide_type_7_img_1 {
    //  min-height: 1080px;
    //  max-height: 1080px;
}

.slide_type_7_img_2,
.slide_type_7_img_3 {
    // min-height: 540px;
    // max-height: 540px;
}


/*8 - 2 square and 1 vertical images*/

.slide_type_8_img_1,
.slide_type_8_img_2 {
    // min-height: 540px;
    // max-height: 540px;
}

.slide_type_8_img_3 {
    // min-height: 1080px;
    // max-height: 1080px;
}


/**Bideoen ikonoa**/


// .pswp-gallery{
//     position: relative;
//     z-index: 1;
// }

// .pswp-gallery:before{
//     position:absolute;
//     content: "";
//     background: url(./images/video-card-icon.svg) no-repeat center 50% #44444463;
//     background-size: 10% auto;
//     height: 100%;
//     width: 100%;
//     top: 0;
//     left: 0;
//     z-index: 2;
// }


// .pswp-gallery:before{
//     background: url(./images/video-card-icon.svg) no-repeat center 50% #44444463;
//     background-size: 10% auto;
// }


.pswp-gallery a {
    // position: relative;
    // display: inline-block;


    &:before {
        position: absolute;
        content: "";
        background: url(./images/video-card-icon.svg) no-repeat center 50% #44444463;
        background-size: 10% auto;
        height: 100%;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
    }
}


@media (max-width: 992px) {

    .product-page p.product-description div p {
        margin: 2rem 0 3rem;
        font-size: 20px;
    }

    .product-page .product-title-container {
        height: auto;
    }

    .product-data-container ul {
        padding-left: 1rem !important;
    }

    .product-data-container p {
        margin-bottom: 0.5rem !important;
        line-height: 120%;
    }

    .product-data-container ul li {
        margin-bottom: 0;
    }

    .bm-burger-button,
    .logo-without-menu {
        left: 1rem;
        bottom: 1rem;
    }

    .product-page h1,
    .product-page .h1 {
        margin-left: 1.5rem;
        margin-bottom: 0.5rem !important;
        margin-top: .5rem;
        font-size: 3rem;
    }

    .product-page h2,
    .product-page .h2,
    .product-landing-page h3,
    .product-landing-page .h3 {
        font-size: 1.5rem;
    }

    .product-page h3,
    .product-page .h3 {
        font-size: 18px;
    }

    .product-page p {
        font-size: 16px;
    }

    .product-page .col-md-2 img {
        width: 50px;
        height: 50px;
        margin-left: 1.5rem;
        margin-top: 0;
    }

    .bm-burger-button,
    .logo-without-menu {
        min-width: 70px !important;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
        font-size: 24px;
    }

    .rounded-icon-bg {
        width: 36px;
        height: 36px;
        line-height: 36px;
        padding: 0;
    }

    .tablet-icon svg {
        width: 14px;
    }

    .video-icon svg {
        width: 20px;
    }

    .product-info {
        padding: 0;
    }
}