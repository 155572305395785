$theme-colors: (
    "danger": tomato,
    "warning": orange,
    "success": #bed601
);
$primary: #ed9b33;
$secondary: #333;
$navbar-light-color: rgb(42, 42, 42);
$navbar-light-hover-color: $secondary;
$navbar-light-brand-color: $primary;

//LURREK EGINDA BAINA MAITEK ERABAKITZEKO
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1920px
);
// Custom variable
$navbar-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

