@font-face {
    font-family: "BrutalType-Light";
    src: url("./fonts/BrutalType-Light.ttf");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "BrutalType-Regular";
    src: url("./fonts/BrutalType-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "BrutalType-Bold";
    src: url("./fonts/BrutalType-Bold.ttf");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans-Regular";
    src: url("./fonts/OpenSans-Regular.ttf");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans-Bold";
    src: url("./fonts/OpenSans-Bold.ttf");
    font-weight: bold;
    font-style: normal;
}