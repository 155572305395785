/* Position and sizing of burger button */
.bm-burger-button,
.logo-without-menu {
  position: fixed;
  width: 8rem;
  width: 6.3vw;
  height: auto;
  left: 2rem;
  bottom: 1.5rem;
  z-index: 5;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  top: 15px !important;
  right: 20px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 25% !important;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  /* color: #b8b7ad; */
  padding: 1em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.logo-inside-menu {
  width: 6.3vw;
  height: auto;
  margin: 4rem 0 2rem;
}

.bm-item {
  margin: 2rem 0;
}

.menu-link-image {
  max-width: 100%;
  height: auto;
}

.menu-link {
  border-bottom: 1px solid #ccc;
}

.menu-link:first-of-type {
  border-top: 1px solid #ccc;
}

.menu-link .nav-link {
  padding: 0.5rem 0;
}

.menu-link .row {
  align-items: center;
}

/**Menua**/

.nav-link {
  line-height: 105% !important;
}

.nav-link h3 {
  font-size: 28px;
  font-size: clamp(0.875rem, 0.2737rem + 1.2302vw, 1.75rem);
  font-family: 'BrutalType-Bold';
  color: #333;
  margin: 0 !important;
  line-height: 105% !important;
}

.nav-link p {
  color: #333;
  text-transform: uppercase;
  font-size: 20px;
  opacity: 0.5;
  font-size: clamp(0.625rem, 0.1955rem + 0.8787vw, 1.25rem);
  margin: 0 !important;
  line-height: 105% !important;
}

.setting-icon {
  fill: none;
  stroke: #ccc;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 32px;
}


@media (max-width: 960px) {

.setting-icon {
  width: 16px;
}
}