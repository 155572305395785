@import "./variables";
@import "~bootstrap/scss/bootstrap";
@import "./fonts";
@import "./product_landing_page";
@import "./product_main_page";
@import "./product_page";
@import "./video-gallery";
@import "./carousel";
@import "./spinner";
//Azpiko hau orain index-ean dagoen irudiarentzat da. BORRATZEKO DA
// .imgbox {
//     display: grid;
//     height: 100%;
// }
.home-image {
    margin: auto;
    object-fit: cover;
    object-position: center;
    width: 100%;
    max-height: 1080px;
    min-height: 1080px;
}

b,
strong {
    font-weight: bolder;
    font-family: 'OpenSans-Bold';
}

ul{
    padding-left: 1.5rem
}

.product-page, .product-landing-page, .product-main-page {
    width: 100%;
    height: 100vh;
    margin: auto;
}

.admin-panel-container{
    // width: 100%;
    background: #efefef;
   height: 100vh;
   margin:auto;
   padding: 15vh 33vh;
}

.admin-panel{
    background: #fff;
    border-radius: 20px;
    padding: 2rem;
    width: 80%;
    margin: auto;
    min-height: 34vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.admin-panel .btn{
    background: #ed9b33;
    color: #333;
    text-transform: uppercase;
    font-size: 1rem;
    border-radius: 0;
    padding:  0.375rem 1.75rem ;
    font-weight: bold;
    margin-top: 3rem;
    margin-left: auto;
}

.admin-panel .btn:hover{
    background: #333;
    color: #fff;
}

.admin-panel h1{
   font-size: clamp(1.25rem, 0.1333rem + 2.2847vw, 2.875rem) !important;
}
.admin-panel h2{
    font-family: "BrutalType-light";
    font-size: 32px;
    font-size: clamp(1.125rem, 0.5237rem + 1.2302vw, 2rem);
    margin: 0;
}
body{
  font-family: "OpenSans-Regular", sans-serif;
  color: #333;
  font-size: 26px;
  font-size: clamp(1rem, 0.5705rem + 0.8787vw, 1.625rem);
}

h1, h2, h3{
    font-family: "BrutalType-Bold";

}

h1{
    font-size: 150px;
    font-size: clamp(3.125rem, -1.1698rem + 8.7873vw, 9.375rem);
}

h2{
    font-size: 46px;
    font-size: clamp(1.25rem, 0.1333rem + 2.2847vw, 2.875rem);
}

h3{
    font-size: 32px;
    font-size: clamp(1.125rem, 0.5237rem + 1.2302vw, 2rem);

}

.container {
    --bs-gutter-x: 0;
    width: 100%;
    max-width: 100%;
}

.swiper-button-prev, .swiper-button-next {
    color: #efefef;
// text-shadow: 0 0 10px #FFFFFF;
  }
  .swiper-button-next {
    color: #efefef;
  }

.swiper-button-disabled {
    display: none;
}
